
import { defineComponent } from "vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import FlatButton from "@/components/atomics/button/FlatButton.vue";
import { RepositoryFactory, AuthRepository } from "@/lib/https";
import { storage, key } from "@/lib/storage";
import useFormHandler from "@/lib/compositional-logic/useFormHandler";
import { useRouter } from "vue-router";
import useMenuList from "@/lib/compositional-logic/useMenuList";

export default defineComponent({
  name: "Login",
  components: {
    ValidationTextField,
    FlatButton,
  },

  setup() {
    const router = useRouter();

    const { loginByEmail } =
      RepositoryFactory.getRepository<AuthRepository>(AuthRepository);

    const { formHandle, form } = useFormHandler(
      {
        email: "",
        password: "",
      },
      loginByEmail
    );
    const submit = async function () {
      await formHandle()
        .then(({ data }) => {
          const localStorage = storage.getLocalStorage();
          localStorage.add(key.userId, data.userId ?? "");
          const { onMenuListReady, menuList } = useMenuList();
          onMenuListReady((value) => {
            if (value) router.push({ name: menuList.value[0].name });
          });
        })
        .catch((e) => {
          form.setErrors({ email: e.data.message });
        });
    };

    return {
      submit,
    };
  },
});
